import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { Link } from 'preact-router/match';

const VERTICAL_MENU_ITEM_TRANSITION_TIME = 250;
const MAGIC_DECKMATE_SLUG = 'magic-deckmate';
const KICK_TICKER_SLUG = 'kick-ticker';
const SPREAD_SLUG = 'spread';

const Header = (props) => {
	const [verticalMenuItemsShown, setVerticalMenuItemsShown] = useState([false, false, false, false]);
	const [headerStyleMode, setHeaderStyleMode] = useState();
	
	const handleMenuClick = () => {
		for (let i = 0; i < verticalMenuItemsShown.length; i++) {
			setTimeout(() => {
				setVerticalMenuItemsShown((menuItems) => {
					menuItems[i] = !menuItems[i];
					return [...menuItems];
				});
			}, VERTICAL_MENU_ITEM_TRANSITION_TIME * i);
		}
	};

	const handleAboutClick = () => {
		// Check the route
		if (props.currentUrl === '/') {
			// If the route is home, moveTo About Section
			window.fullpage_api.moveTo('about');
		} else {
			// Otherwise, navigate as normal
			route('/#about');
		}
	}

	const setBodyClass = (slug) => {
		document.body.classList.remove(MAGIC_DECKMATE_SLUG, KICK_TICKER_SLUG, SPREAD_SLUG);

		if (document.body.classList && slug) {
			document.body.classList.add(slug);
		}
	}

	useEffect(() => {
		if (props.currentUrl && props.currentUrl.includes(MAGIC_DECKMATE_SLUG)) {
			setHeaderStyleMode(MAGIC_DECKMATE_SLUG);
			setBodyClass(MAGIC_DECKMATE_SLUG);
		} else if (props.currentUrl && props.currentUrl.includes(KICK_TICKER_SLUG)) {
			setHeaderStyleMode(KICK_TICKER_SLUG);
			setBodyClass(KICK_TICKER_SLUG);
		} else if (props.currentUrl && props.currentUrl.includes(SPREAD_SLUG)) {
			setHeaderStyleMode(SPREAD_SLUG);
			setBodyClass(SPREAD_SLUG);
		} else {
			setHeaderStyleMode('');
			setBodyClass('');
		}
	}, [props.currentUrl]);

	return (
		<header id="header" class={`header ${headerStyleMode}`}>
			<div class="container">
				<div class="header__row">
					<a
						activeClassName="active"
						class="menu__button"
						data-menuanchor="home"
						native
						href="/#home">
						<h1 class="d-none d-lg-block">Switch Kick Studios</h1>
						<h1 class="d-block d-lg-none">SKS</h1>
					</a>
					<nav>
						<div class="d-none d-md-block">
							<div class="dropdown horizontal-menu__item">
								<button
									class="dropdown-toggle menu__button"
									role="button"
									id="spreadDropdownMenu"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									Spread
								</button>		
								<div class="dropdown-menu" aria-labelledby="spreadDropdownMenu">
									<Link
										activeClassName="active"
										class="dropdown-item"
										href="/spread">
										Home
									</Link>
									<Link
										activeClassName="active"
										class="dropdown-item"
										href="/spread/how-to">
										How to
									</Link>
								</div>						
							</div>
							<Link
								activeClassName="active"
								class="horizontal-menu__item menu__button"
								href="/magic-deckmate">
								Magic Deckmate
							</Link>
							<Link
								activeClassName="active"
								class="horizontal-menu__item menu__button"
								href="/kick-ticker">
								Kick Ticker
							</Link>
							<Link
								activeClassName="active"
								class="horizontal-menu__item menu__button"
								onClick={handleAboutClick}
								href="/#about">
								About
							</Link>
						</div>
						<div class="d-block d-md-none">
							<button class="vertical-menu__button menu__button" onClick={handleMenuClick}>
								<span class="captainIcon icon-316" />
							</button>
						</div>
					</nav>
				</div>

				<nav class="d-md-none accordion" id="spreadAccordionMenu">
					<div class={`vertical-menu__item ${verticalMenuItemsShown[0] ? 'show' : ''}`}>
						<button
							class="collapsed menu__button menu__toggle-item"
							id="spreadCollapseHeader"
							type="button"
							data-toggle="collapse"
							data-target="#spreadCollapse"
							aria-expanded="true"
							aria-controls="spreadCollapse">
							Spread
						</button>
						<div
							id="spreadCollapse"
							class="collapse"
							aria-labelledby="spreadCollapseHeader"
							data-parent="#spreadAccordionMenu">
							<Link
								activeClassName="active"
								class="dropdown-item"
								href="/spread">
								Home
							</Link>
							<Link
								activeClassName="active"
								class="dropdown-item"
								href="/spread/how-to">
								How to
							</Link>
						</div>
					</div>
					<Link
						activeClassName="active"
						class={`vertical-menu__item menu__button ${verticalMenuItemsShown[1] ? 'show' : ''}`}
						href="/magic-deckmate">
						Magic Deckmate
					</Link>
					<Link
						activeClassName="active"
						class={`vertical-menu__item menu__button ${verticalMenuItemsShown[2] ? 'show' : ''}`}
						href="/kick-ticker">
						Kick Ticker
					</Link>
					<Link
						activeClassName="active"
						class={`vertical-menu__item menu__button ${verticalMenuItemsShown[3] ? 'show' : ''}`}
						onClick={handleAboutClick}
						href="/#about">
						About
					</Link>
				</nav>
			</div>
		</header>
	);
	}

export default Header;
