
const initialState = {
    headerModifier: '',
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case "setHeaderModifier":
            return { headerModifier: action.value };
        default:
            return state;
    }
}

export default reducer;