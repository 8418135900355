export const userGuideParser = (data) => {
    if (!data.items || !data.items.length) {
        return null;
    }

    const pageData = data.items.map(item => {
        return {
            content: item.fields.content,
            id: item.sys.id,
            title: item.fields.title,
        }
    });

    return pageData;
};
