import { h } from 'preact';
import { useState } from 'preact/hooks';
import Scrollspy from 'react-scrollspy';

const UserGuideMenuMobile = (props) => {
	const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

    const handleSidebarButtonToggleClick = () => {
        setSidebarIsOpen(isOpen => !isOpen);
    }

    const handleCloseSidebar = () => {
        setSidebarIsOpen(() => false);
    }

    const getSideBarIsOpen = () => {
        return sidebarIsOpen ? 'open' : '';
    }

    const renderMenuItems = () => {
        if (!props.menuData || !props.menuData.length) {
            return null;
        }

        console.log(props);

        return props.menuData.map(item => {
            return (
                <li>
                    <a href={`#${item.id}`}>{item.title}</a>
                </li>
            );
        });
    };

    const getScrollSpyItems = () => props.menuData.map(item => item.id);

    return (
        <div class="user-guide-menu-mobile__container">
            <div class="container">
                <button
                    class={`menu__button menu__toggle-item user-guide-menu-mobile__toggle-button ${getSideBarIsOpen()}`}
                    role="button"
                    onClick={handleSidebarButtonToggleClick}>
                    <h2>How to</h2>
                </button>
            </div>
            <div class={`user-guide-menu-mobile__sidebar-background ${getSideBarIsOpen()}`}>
                <button role="button" onClick={handleCloseSidebar}>
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <nav class={`user-guide-menu-mobile__sidebar ${getSideBarIsOpen()}`} >
                <div class="container">
                    <Scrollspy items={getScrollSpyItems()} currentClassName="scrollspy-active">
                        {renderMenuItems()}
                    </Scrollspy>
                </div>
            </nav>
        </div>
    );
};

export default UserGuideMenuMobile;