import { h } from 'preact';
import { Router } from 'preact-router';
import AsyncRoute from 'preact-async-route';
import { useState } from 'preact/hooks';
import { createStore } from 'redux'
import { Provider } from 'react-redux';
import { ContentfulClient, ContentfulProvider } from 'react-contentful';

// Code-splitting is automated for routes
import keys from '../config/keys';
import Header from './header';
import Home from '../routes/home';
import MagicDeckmate from '../routes/magic-deckmate';
import KickTicker from '../routes/kick-ticker';
import KickTickerPrivacyPolicy from '../routes/kick-ticker/routes/privacy-policy';
import Spread from '../routes/spread';
import SpreadHowTo from '../routes/spread/routes/how-to';
import SpreadPrivacyPolicy from '../routes/spread/routes/privacy-policy';
import SpreadJoinBand from '../routes/spread/routes/join-band';
import reducer from '../state/reducer';

const contentfulClient = new ContentfulClient({
	accessToken: keys.contentfulAccessToken,
	space: keys.contentfulSpaceID,
});

const App = () => {
	const store = createStore(reducer)

	const [currentUrl, setCurrentUrl] = useState(null);

	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	const handleRoute = e => {
		setCurrentUrl(e.url);
	};

	return (
		<Provider store={store}>
			<ContentfulProvider client={contentfulClient}>
				<div id="app">
					<Header currentUrl={currentUrl} />
					<Router onChange={handleRoute}>
						<AsyncRoute path="/" component={Home} />
						<AsyncRoute path="/magic-deckmate/" component={MagicDeckmate} />
						<AsyncRoute path="/kick-ticker/" component={KickTicker} />
						<AsyncRoute path="/kick-ticker/privacy-policy" component={KickTickerPrivacyPolicy} />
						<AsyncRoute path="/spread/" component={Spread} />
						<AsyncRoute path="/spread/how-to" component={SpreadHowTo} />
						<AsyncRoute path="/spread/join-band/:invitationCode" component={SpreadJoinBand} />
						<AsyncRoute path="/spread/privacy-policy/" component={SpreadPrivacyPolicy} />
					</Router>
				</div>
			</ContentfulProvider>
		</Provider>
	);
};

export default App