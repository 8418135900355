import { h } from 'preact';
import Scrollspy from 'react-scrollspy';

const UserGuideMenuDesktop = (props) => {
    const renderMenuItems = () => {
        if (!props.menuData || !props.menuData.length) {
            return null;
        }

        console.log(props);

        return props.menuData.map(item => {
            return (
                <li>
                    <a href={`#${item.id}`}>{item.title}</a>
                </li>
            );
        });
    };

    const getScrollSpyItems = () => props.menuData.map(item => item.id);

    return (
        <nav class="user-guide-menu-desktop__container">
            {props.logo}
            <h2 class="user-guide-menu-desktop__header">How to:</h2>
            <Scrollspy items={getScrollSpyItems()} currentClassName="scrollspy-active">
                {renderMenuItems()}
            </Scrollspy>
        </nav>
    );
};

export default UserGuideMenuDesktop;