import { h } from 'preact';
import sksLinks from '../../../../config/sks-links';
import Footer from '../../../../components/footer';

const SpreadJoinBand = ({ invitationCode }) => (
	<div class="spread-join-band__container">
		<div class="container spread-join-band__content-container">
			<img
				src="../../../../assets/images/spread/logo_main_digital.png"
				alt="Spread"
				class="spread-join-band__logo" />
			<div class="spread-join-band__instructions-header">Here is your invitation code:</div>
			<div class="spread-join-band__invitation-code">{invitationCode}</div>
			<div class="spread-join-band__instructions-header">To accept your invitation:</div>
			<ol>
				<li>If you aren't already logged in, log into Spread: The Application of Jam.</li>
				<li>Click the link below. Or, go to the Join Band screen within Spread and submit your invitation code.</li>
			</ol>
			<div class="spread-join-band__link-to-app">
                <a href={`spread://join-band?invitationCode=${invitationCode}`}>Join Band</a>
            </div>
			<div class="spread-join-band__instructions-header">Don't Have Spread? Download it for free:</div>
			<div class="spread-join-band__store-links">
				<a href={sksLinks.spread.googlePlayStore}>
					<img
						src="../../../../assets/images/google-play-badge.png"
						alt="Get it on Google Play"
						class="spread-join-band__google-play-button" />
				</a>
				<a href={sksLinks.spread.appStore}>
					<img
						src="../../../../assets/images/Download_on_the_App_Store_Badge_US-UK_135x40.svg"
						alt="Download on the App Store"
						class="spread-join-band__app-store-button" />
				</a>
			</div>
		</div>
		<Footer />
	</div>
);

export default SpreadJoinBand;
