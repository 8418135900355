import { h } from 'preact';

import SpreadPrivacyPolicyContent from './privacy-policy-content';
import Footer from '../../../../components/footer';

const SpreadPrivacyPolicy = () => (
	<div class="spread-privacy-policy__container">
		<div class="container spread-privacy-policy__content-container">
			<h2>Spread Privacy Policy</h2>
			<SpreadPrivacyPolicyContent />
		</div>
		<Footer />
	</div>
);

export default SpreadPrivacyPolicy;
