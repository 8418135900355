import { h } from 'preact';

import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import UserGuideImage from '../user-guide-image';

const UserGuideSection = (props) => {

    const renderDocument = doc => {
        if (!doc) {
            return null;
        }

        const options = {
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node) => {
                    const fields = node.data.target.fields;
                    return <UserGuideImage url={fields.file.url} title={fields.title} />;
                }
            }
        }
        
        return documentToReactComponents(doc, options);
    };


    return (
        <article class="user-guide-section__container" id={props.id}>
            <h2>{props.title}</h2>
            {renderDocument(props.content)}
        </article>
    );
};

export default UserGuideSection;