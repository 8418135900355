import { h } from 'preact';

const UserGuideImage = (props) => {
    return (
        <div class="user-guide-image__container">
            <img src={props.url} alt={props.title} />
        </div>
    );
};

export default UserGuideImage;