import { h } from 'preact';

import Footer from '../../../../components/footer';

const KickTickerPrivacyPolicy = () => (
	<div class="kick-ticker-privacy-policy__container">
		<div class="container kick-ticker-privacy-policy__content">
			<h2>Privacy Policy</h2>
			<div>Kick Ticker does not collect any data from users.</div>
		</div>
		<Footer />
	</div>
);

export default KickTickerPrivacyPolicy;
