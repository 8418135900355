import { h } from 'preact';
import { useContentful } from 'react-contentful';

import { userGuideParser } from '../../../../utils/parsers';
import Footer from '../../../../components/footer';
import UserGuideSection from '../../../../components/user-guide-section';
import UserGuideMenuDesktop from '../../../../components/user-guide-menu-desktop';
import UserGuideMenuMobile from '../../../../components/user-guide-menu-mobile';
import UnderConstruction from '../../../../components/under-construction';

const SpreadHowTo = () => {
    // eslint-disable-next-line no-unused-vars
    const { data, error, fetched, loading } = useContentful({
        contentType: 'spreadHowTo',
        query: {
            order: 'fields.orderId'
        },
        parser: userGuideParser,
    });

    const renderMenuDesktop = data => {
        if (!data || !data.length) {
            return null;
        }

        const menuData = data.map(doc => ({
            id: doc.id,
            title: doc.title,
        }));

        return <UserGuideMenuDesktop menuData={menuData} logo={renderMenuLogo()} />;
    };

    const renderMenuMobile = data => {
        if (!data || !data.length) {
            return null;
        }

        const menuData = data.map(doc => ({
            id: doc.id,
            title: doc.title,
        }));

        return <UserGuideMenuMobile menuData={menuData} />;
    };

    const renderMenuLogo = () => (
        <img
            src="../../../../assets/images/spread/logo_main_digital.png"
            alt="Spread"
            class="spread-how-to__menu-logo" />
    );

    const renderDocuments = data => {
        if (!data || !data.length) {
            return null;
        }

        return data.map(doc => {
            return <UserGuideSection { ...doc} />;
        })
    };

    return (
        <div class="spread-how-to__container">
            <div className="d-md-none spread-how-to__menu--mobile">
                {renderMenuMobile(data)}
            </div>
            <div class="container">
                {/* <UnderConstruction /> */}
                <div class="row spread-how-to__row">
                    <div class="d-none d-md-block spread-how-to__menu--desktop">
                        {renderMenuDesktop(data)}
                    </div>
                    <div class="col spread-how-to__content">
                        {renderDocuments(data)}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default SpreadHowTo;
