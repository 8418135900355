const sksLinks = {
    spread: {
        googlePlayStore: 'http://play.google.com/store/apps/details?id=com.switchkickstudios.spread',
        appStore: 'https://apps.apple.com/us/app/id1514530970',
    },
    kickTicker: {
        googlePlayStore: '',
        appStore: 'https://itunes.apple.com/us/app/kick-ticker/id1436800669?ls=1&mt=8',
    },
    magicDeckmate: {
        appStore: 'https://itunes.apple.com/us/app/magic-deckmate/id1186058352?ls=1&mt=8n'
    }
}

export default sksLinks;
