import { h } from 'preact';

import links from '../../config/external-links';

const Footer = () => {
    return (
        <footer class="footer__container">
            <div class="container footer__content-container">
                <div class="row">
                    <div class="col footer__contact-col">
                        <div class="footer__contact-label">Contact:</div>
                        <div class="footer__contact-item">
                            <div class="footer__contact-item-label">Email:</div>
                            <div class="footer__contact-item-value">conor@switchkickstudios.com</div>
                        </div>
                        <div class="footer__contact-item">
                            <div class="footer__contact-item-label">Support:</div>
                            <div class="footer__contact-item-value">support@switchkickstudios.com</div>
                        </div>
                    </div>
                    <div class="col footer__social-col">
                        <a href={links.SKS_TWITTER_URL} target="_blank" rel="noreferrer">
                            <span class="fab fa-twitter footer__social-icon" />
                        </a>
                        <a href={links.SKS_INSTAGRAM_URL} target="_blank" rel="noreferrer">
                            <span class="fab fa-instagram footer__social-icon" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;